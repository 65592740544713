import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { PoliceZones } from './components/PoliceZones';
import { UserManagement } from './components/UserManagement';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import { ManageBins } from './components/ManageBins';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/manage-bins/:id' component={ManageBins} />
                <AuthorizeRoute path='/police-zones' component={PoliceZones} />
                <AuthorizeRoute path='/all-bin-data' component={FetchData} />
                <AuthorizeRoute path='/user-management' component={UserManagement} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
