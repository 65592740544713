import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from 'reactstrap';
import { EditPoliceZone } from './EditPoliceZone';
import Alert from 'react-bootstrap/Alert';
import SendMail from './SendMail';
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';

export class UserManagement extends Component {
    static displayName = 'Gebruikers management';
        

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
        };
    }

    async fetchUsers() {
        const token = await authService.getAccessToken();
        const response = await fetch('/user', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        if (response.status === 403) {
            this.setState({ noAccess: true, loading: false });
            return;
        }

        const data = await response.json();
        this.setState({ users: data, loading: false });
    }

    componentDidMount() {
        this.fetchUsers();
    }

    async changeAccess(userId, needsAccess) {
        const token = await authService.getAccessToken();
        const response = await fetch('/user/' + userId + '?isAdmin=' + needsAccess, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        if (response.status === 403) {
            this.setState({ noAccess: true, loading: false });
            return;
        }
    }

    async removeUser(userId) {
        const token = await authService.getAccessToken();
        const response = await fetch('/user/' + userId, {
            method: 'DELETE',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        if (response.status === 403) {
            this.setState({ noAccess: true, loading: false });
            return;
        }

        const newUsers = [];

        this.state.users.forEach((user) => {
            if (userId !== user.id) {
                newUsers.push(user);
            }
        });

        this.setState({
            users: newUsers,
        });
    }

    render() {
        return (
            <div>
                <h1>Gebruikers management</h1>

                <p>Beheer van de gebruikers. Verleen hier gebruikers toegang tot de admin interface.</p>

                {this.state.noAccess &&
                    <Alert variant="warning">U heeft niet de nodige toegangsrechten om deze data te bewerken. Neem contact op met uw beheerder om u de nodige rechten te verlenen. Indien u deze boodschap onterecht krijgt kan u proberen aan en afmelden om uw rechten te vernieuwen.</Alert>
                }
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Toegang tot beheer</th>
                            <th className="text-right">Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map((user) => {
                                return (
                                    <tr key={user.id}>
                                        <td>{user.userName}</td>
                                        <td>
                                            <input type="checkbox" onChange={(e) => this.changeAccess(user.id, e.target.checked)} defaultChecked={user.isAdmin} />
                                        </td>
                                        <td className="text-right">
                                            <div className="text-right">
                                                <ButtonGroup>
                                                    <Button onClick={(e) => this.removeUser(user.id)} variant="danger">
                                                        <Icon.Trash />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}
