import React, { createRef } from 'react'
import authService from './AuthorizeService'

export function AuthenticatedLink({ url, filename, children }) {
    const handleAction = async (e) => {
        const token = await authService.getAccessToken();
        const result = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);


        const link = document.createElement("a");

        link.download = filename;
        link.href = href;

        link.click();
    }

    return (
        <button type='button' onClick={handleAction} className="btn btn-primary">{children}</button>
    );
}
