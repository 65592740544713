import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { GoogleMap } from '@react-google-maps/api';
const tj = require("@tmcw/togeojson");
const bbox = require('geojson-bbox');

const ShowKmlMap = ({ kml = '',
    show = false,
    handleClose = () => console.log('close modal'),
}) => {

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const loadPolygons = (map) => {
        let kmlText = kml;

        if (kmlText !== '' && map != null) {

            try {
                const kmlDoc = new DOMParser().parseFromString(kmlText, 'text/xml');

                map.data.forEach(function (f) {
                    map.data.remove(f);
                });
                const geojson = tj.kml(kmlDoc);
                map.data.addGeoJson(geojson);

                const bounds = bbox(geojson);
                var top = new window.google.maps.LatLng(bounds[1], bounds[0]);
                var bottom = new window.google.maps.LatLng(bounds[3], bounds[2]);
                const mapBounds = new window.google.maps.LatLngBounds(top, bottom);
                map.fitBounds(mapBounds);
            }
            catch (e) { console.log(e); }
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>KML</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={map => {
                        const bounds = new window.google.maps.LatLngBounds();
                        map.fitBounds(bounds);

                        map.data.setControls(['Polygon']);
                        map.data.setStyle({
                            editable: true,
                            draggable: true,
                        });

                        loadPolygons(map);
                    }}
                    onUnmount={map => {
                        // do your stuff before map is unmounted
                    }}>
                </GoogleMap>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ShowKmlMap };
