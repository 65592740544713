import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import authService from './api-authorization/AuthorizeService';

const SendMail = ({
    email = '',
    show = false,
    handleClose = () => console.log('close modal'),
}) => {

    const cancel = () => {
        handleClose(false);
    };

    const sendMail = () => {
        handleClose(false);
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Email versturen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="mailform">
                    <div className="form-group">
                        <label htmlFor="subject">Onderwerp</label>
                        <input type="text"
                            className="form-control"
                            id="subject"
                            placeholder="Onderwerp"
                            required="required" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <textarea
                            rows="8"
                            className="form-control"
                            id="email"
                            required="required" />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancel}>
                    Sluiten
                </Button>
                <Button variant="success" onClick={sendMail}>
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendMail;
