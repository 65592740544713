import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import { AuthenticatedLink } from './api-authorization/AuthenticatedLink';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import * as Icon from 'react-bootstrap-icons';
import { ShowKmlMap } from './ShowKmlMap';
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';

export class FetchData extends Component {
    static displayName = "Buurt informatie netwerken";
    static mapLibs = ['drawing'];

    constructor(props) {
        super(props);
        this.state = {
            networks: [],
            loading: true,
            token: null,
            showMap: false,
            selectedKml: '',
            noAccess: false,
        };
    }

    componentDidMount() {
        this.populateNetworksData();
    }

    async removeNetwork(e, network) {
        const token = await authService.getAccessToken();
        const response = await fetch('/neighbourhoodinformationnetwork/' + network.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        const newNetworks = [];

        this.state.networks.forEach((pz) => {
            if (pz.id !== network.id) {
                newNetworks.push(pz);
            }
        });

        this.setState({
            networks: newNetworks,
        });

        e.preventDefault();
    }

    renderNetworksTable(networks) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Gemeente</th>
                        <th>Naam</th>
                        <th>Gemandateerde politie</th>
                        <th>Coordinator</th>
                        <th>Map</th>
                        <th className="text-right">Acties</th>
                    </tr>
                </thead>
                <tbody>
                    {networks.map(network =>
                        <tr key={network.id}>
                            <td>{network.policeZone.name}</td>
                            <td>{network.municipality}</td>
                            <td>{network.name}</td>
                            <td>{network.binPoliceContact}</td>
                            <td>{network.binCoordinator}</td>
                            <td>
                                <Button variant="secondary" onClick={() => this.showMap(network.kml)}>
                                    <Icon.Map></Icon.Map>
                                </Button>
                            </td>
                            <td className="text-right">
                                <Button onClick={(e) => this.removeNetwork(e, network)} variant="danger">
                                    <Icon.Trash></Icon.Trash>
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    handleClose() {
        this.setState({
            showMap: false,
        });
    }

    showMap(kml) {
        this.setState({
            selectedKml: kml,
            showMap: true,
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNetworksTable(this.state.networks);

        return (
            <div>
                <div className="row">
                    <h1 id="tabelLabel" className="col-sm-8">Ingevoerde buurt informatie netwerken</h1>
                    {this.state.token &&
                        <div className="col-sm-4 text-right my-auto">
                            <AuthenticatedLink url={'/neighbourhoodinformationnetwork/excel'} filename={'bin-export.xlsx'}>Exporteer</AuthenticatedLink>
                        </div>
                    }
                </div>
                <p>Overzicht van alle BIN netwerken.</p>

                {this.state.noAccess &&
                    <Alert variant="warning">Beheer van de politiezones. Stuur de link door naar de personen die bevoegd zijn voor ingave van de buurt informatie netwerken voor deze zone. Neem contact op met uw beheerder om u de nodige rechten te verlenen. Indien u deze boodschap onterecht krijgt kan u proberen aan en afmelden om uw rechten te vernieuwen.</Alert>
                }

                <LoadScript
                    googleMapsApiKey="AIzaSyC8czhDcYoaNRB2Ee3hmWs4HOVduIlqWvc"
                    libraries={FetchData.mapLibs}
                >
                    <ShowKmlMap show={this.state.showMap} kml={this.state.selectedKml} handleClose={() => this.handleClose()} />
                    {contents}
                </LoadScript>
            </div>
        );
    }

    async populateNetworksData() {
        const token = await authService.getAccessToken();
        const response = await fetch('neighbourhoodinformationnetwork', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        if (response.status === 403) {
            this.setState({ noAccess: true, loading: false });
            return;
        }

        const data = await response.json();
        this.setState({ networks: data, loading: false, token: token });
    }
}
