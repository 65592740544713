import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import * as Icon from 'react-bootstrap-icons';
import { ShowKmlMap } from './ShowKmlMap';
import Button from 'react-bootstrap/Button';
const tokml = require('tokml');
const tj = require("@tmcw/togeojson");
const bbox = require('geojson-bbox');

export class ManageBins extends Component {
    static displayName = "Buurt informatie netwerken";
    static mapLibs = ['drawing'];
    map = null;

    containerStyle = {
        width: '100%',
        height: '400px'
    };

    constructor(props) {
        super(props);
        this.state = {
            networks: [],
            loading: true,
            newNetwork: {
                name: '',
                binPoliceContact: '',
                binCoordinator: '',
                municipality: '',
                kml: '',
                showMap: false,
                selectedKml: '',
            }
        };
    }

    componentDidMount() {
        this.populateNetworksData();
    }

    handleClose() {
        this.setState({
            showMap: false,
        });
    }

    showMap(kml) {
        this.setState({
            selectedKml: kml,
            showMap: true,
        });
    }

    renderNetworksTable(networks) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Gemeente</th>
                        <th>Naam</th>
                        <th>Gemandateerde politie</th>
                        <th>Coordinator</th>
                        <th>Map</th>
                        <th className="text-right">Acties</th>
                    </tr>
                </thead>
                <tbody>
                    {networks.map(network =>
                        <tr key={network.id}>
                            <td>{network.policeZone.name}</td>
                            <td>{network.municipality}</td>
                            <td>{network.name}</td>
                            <td>{network.binPoliceContact}</td>
                            <td>{network.binCoordinator}</td>
                            <td>
                                <Button variant="secondary" onClick={() => this.showMap(network.kml)}>
                                    <Icon.Map></Icon.Map>
                                </Button>
                            </td>
                            <td className="text-right">
                                <button onClick={(e) => this.removeNetwork(e, network)} className="btn btn-danger">Verwijderen</button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    async removeNetwork(e, network) {
        const response = await fetch('/neighbourhoodinformationnetwork/' + network.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const newNetworks = [];

        this.state.networks.forEach((pz) => {
            if (pz.id !== network.id) {
                newNetworks.push(pz);
            }
        });

        this.setState({
            networks: newNetworks,
        });

        e.preventDefault();
    }

    savePolygon(map) {
        console.log("save polygons");

        const self = this;
        map.data.toGeoJson(function (json) {
            const kmlText = tokml(json);

            self.setState({
                newNetwork: { ...self.state.newNetwork, ...{ kml: kmlText } },
            })
        });
    }

    loadPolygons(kml) {
        let kmlText = kml;

        if (!kmlText) {
            kmlText = this.state.newNetwork.kml;
        }

        if (kmlText !== '' && this.map != null) {
            console.log("load polygons");

            try {
                const kmlDoc = new DOMParser().parseFromString(kmlText, 'text/xml');

                this.map.data.forEach(function (f) {
                    this.map.data.remove(f);
                });
                const geojson = tj.kml(kmlDoc);
                this.map.data.addGeoJson(geojson);

                const bounds = bbox(geojson);
                console.log(bounds);
                var top = new window.google.maps.LatLng(bounds[1], bounds[0]);
                var bottom = new window.google.maps.LatLng(bounds[3], bounds[2]);
                const mapBounds = new window.google.maps.LatLngBounds(top, bottom);
                this.map.fitBounds(mapBounds);
            }
            catch (e) { console.log(e); }
        }
    }

    setField(key, value) {
        const newState = {};
        newState[key] = value;
        this.setState({
            newNetwork: { ...this.state.newNetwork, ...newState },
        })

        if (key === 'kml') {
            this.loadPolygons(value);
        }
    }

    readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsText(file);
        })
    }

    async fileUploaded(e) {
        let file = e.target.files[0];
        console.log(file);

        if (file) {
            const kmlText = await this.readFileAsync(file);
            this.setField('kml', kmlText);
        }
    }

    async addNetwork(e) {
        if (document.getElementById("networkform").reportValidity()) {

            try {
                const response = await fetch('/neighbourhoodinformationnetwork', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...this.state.newNetwork, ...{ policeZoneId: this.props.match.params.id } }),
                });

                const newNetwork = await response.json();

                const newNetworks = [...this.state.networks, ...[newNetwork]];
                this.setState({
                    networks: newNetworks,
                    newNetwork: {
                        name: '',
                        binPoliceContact: '',
                        binCoordinator: '',
                        municipality: '',
                        kml: '',
                    },
                });
            }
            catch (e) { console.log(e); }
        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNetworksTable(this.state.networks);
        const self = this;

        return (
            <div>
                <LoadScript
                    googleMapsApiKey="AIzaSyC8czhDcYoaNRB2Ee3hmWs4HOVduIlqWvc"
                    libraries={ManageBins.mapLibs}
                >
                    <h1 id="tabelLabel" >Ingevoerde buurt informatie netwerken</h1>
                    <p>Nieuw buurtinformatie netwerk toevoegen.</p>
                    <form id="networkform">
                        <div className="form-group">
                            <label htmlFor="name">Naam</label>
                            <input type="text" className="form-control" id="name" placeholder="Naam van het netwerk" required="required" value={this.state.newNetwork.name} onChange={(e) => this.setField('name', e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="binPoliceContact">Naam gemandateerde politie</label>
                            <input type="text" className="form-control" id="binPoliceContact" placeholder="Naam gemandateerde politie" required="required" value={this.state.newNetwork.binPoliceContact} onChange={(e) => this.setField('binPoliceContact', e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="binCoordinator">Naam coordinator</label>
                            <input type="text" className="form-control" id="binCoordinator" placeholder="Naam coordinator" required="required" value={this.state.newNetwork.binCoordinator} onChange={(e) => this.setField('binCoordinator', e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="municipality">Gemeente</label>
                            <input type="text" className="form-control" id="municipality" placeholder="Gemeente" required="required" value={this.state.newNetwork.municipality} onChange={(e) => this.setField('municipality', e.target.value)} />
                        </div>
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label htmlFor="kml">KML</label>
                                <textarea className="form-control" id="kml" required="required" value={this.state.newNetwork.kml} rows="6" onChange={(e) => this.setField('kml', e.target.value)}>
                                </textarea><br />
                                <input type="file" id="kmlFile" placeholder="Upload kml bestand" accept=".kml" onChange={(e) => this.fileUploaded(e)} />
                            </div>
                            <div className="col-sm-6">
                                <GoogleMap
                                    mapContainerStyle={this.containerStyle}
                                    onLoad={map => {
                                        const bounds = new window.google.maps.LatLngBounds();
                                        map.fitBounds(bounds);

                                        map.data.setControls(['Polygon']);
                                        map.data.setStyle({
                                            editable: true,
                                            draggable: true,
                                        });

                                        map.data.addListener('addfeature', () => self.savePolygon(map));
                                        map.data.addListener('removefeature', () => self.savePolygon(map));
                                        map.data.addListener('setgeometry', () => self.savePolygon(map));
                                        self.map = map;

                                        self.loadPolygons();
                                    }}
                                    onUnmount={map => {
                                        // do your stuff before map is unmounted
                                    }}>
                                </GoogleMap>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="button" className="btn btn-success" onClick={(e) => this.addNetwork(e)}> Toevoegen</button>
                        </div>
                    </form>
                    <ShowKmlMap show={this.state.showMap} kml={this.state.selectedKml} handleClose={() => this.handleClose()} />
                    <p>Overzicht van alle BIN netwerken.</p>
                    {contents}
                </LoadScript>
            </div>
        );
    }

    async populateNetworksData() {
        const response = await fetch('neighbourhoodinformationnetwork/' + this.props.match.params.id, {});
        const data = await response.json();
        this.setState({ networks: data, loading: false });
    }
}
