import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from 'reactstrap';
import { EditPoliceZone } from './EditPoliceZone';
import Alert from 'react-bootstrap/Alert';
import SendMail from './SendMail';
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';

export class PoliceZones extends Component {
    static displayName = 'Politie zones';
        

    constructor(props) {
        super(props);
        this.state = {
            policeZones: [],
            loading: true,
            newName: '',
            newCoordinator: '',
            showEdit: false,
            selectedPoliceZone: '',
            showSendEmail: false,
            selectedEmail: '',
            noAccess: false,
        };
    }

    async fetchPoliceZones() {
        const token = await authService.getAccessToken();
        const response = await fetch('/policezone', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        if (response.status === 403) {
            this.setState({ noAccess: true, loading: false });
            return;
        }

        const data = await response.json();
        this.setState({ policeZones: data, loading: false });
    }

    componentDidMount() {
        this.fetchPoliceZones();
    }

    async removePoliceZone(e, policeZone) {
        const token = await authService.getAccessToken();
        const response = await fetch('/policezone/' + policeZone.id, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        const newPoliceZones = [];

        this.state.policeZones.forEach((pz) => {
            if (pz.id !== policeZone.id) {
                newPoliceZones.push(pz);
            }
        });

        this.setState({
            policeZones: newPoliceZones,
        });

        e.preventDefault();
    }

    async updatePoliceZone(policeZone) {
        const newPoliceZones = [];

        this.state.policeZones.forEach((pz) => {
            if (pz.id !== policeZone.id) {
                newPoliceZones.push(pz);
            } else {
                newPoliceZones.push(policeZone);
            }
        });

        this.setState({
            policeZones: newPoliceZones,
        });
    }

    async addPoliceZone(e) {
        if (this.state.newName !== '' && this.state.newCoordinator !== '') {

            const policeZone = {
                name: this.state.newName,
                mainContact: this.state.newCoordinator,
            };

            const token = await authService.getAccessToken();
            const response = await fetch('/policezone', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(policeZone),
            });

            const newPoliceZone = await response.json();

            const newPoliceZones = [ ...this.state.policeZones, ...[newPoliceZone] ];
            this.setState({
                newName: '',
                newCoordinator: '',
                policeZones: newPoliceZones,
            });
        }

        e.preventDefault();
    }

    setNewPoliceZoneName(e) {
        this.setState({
            newName: e.target.value,
        });
    }

    setNewPoliceZoneCoordinatorName(e) {
        this.setState({
            newCoordinator: e.target.value,
        });
    }

    handleClose(changed, policeZone) {
        if (changed) {
            this.updatePoliceZone(policeZone);
        }

        this.setState({
            showEdit: false,
        });
    }

    showEdit(policeZone) {
        this.setState({
            selectedPoliceZone: policeZone,
            showEdit: true,
        });
    }

    showSendEmail(email) {
        this.setState({
            selectedEmail: email,
            showSendEmail: true,
        });
    }

    handleEmailClose() {
        this.setState({
            showSendEmail: false,
        });
    }

    render() {
        return (
            <div>
                <h1>Politie zones</h1>

                <p>Beheer van de politiezones. Stuur de link door naar de personen die bevoegd zijn voor ingave van de buurt informatie netwerken voor deze zone.</p>

                {this.state.noAccess &&
                    <Alert variant="warning">U heeft niet de nodige toegangsrechten om deze data te bewerken. Neem contact op met uw beheerder om u de nodige rechten te verlenen. Indien u deze boodschap onterecht krijgt kan u proberen aan en afmelden om uw rechten te vernieuwen.</Alert>
                }

                <SendMail show={this.state.showSendEmail} email={this.state.selectedEmail} handleClose={() => this.handleEmailClose()} />
                <EditPoliceZone show={this.state.showEdit} policeZone={this.state.selectedPoliceZone} handleClose={(changed, policeZone) => this.handleClose(changed, policeZone)} />

                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th>Email contactpersoon</th>
                            <th>#BINs</th>
                            <th>Link</th>
                            <th className="text-right">Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.policeZones.map((policeZone) => {
                                return (
                                    <tr key={policeZone.id}>
                                        <td>{policeZone.name}</td>
                                        <td>{policeZone.mainContact}</td>
                                        <td>{policeZone.numberOfNetworks}</td>
                                        <td>
                                            <a href={"/manage-bins/" + policeZone.id} target="_blank">link</a>
                                        </td>
                                        <td className="text-right">
                                            <div className="text-right">
                                                <ButtonGroup>
                                                    <Button onClick={(e) => this.showSendEmail(policeZone.mainContact)} variant="primary">
                                                        <Icon.Envelope />
                                                    </Button>
                                                    <Button onClick={(e) => this.showEdit(policeZone)} variant="primary">
                                                        <Icon.Pencil />
                                                    </Button>
                                                    <Button onClick={(e) => this.removePoliceZone(e, policeZone)} variant="danger">
                                                        <Icon.Trash />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    );
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <input type="text"
                                    placeholder="Naam politiezone"
                                    className="form-control"
                                    onChange={(e) => this.setNewPoliceZoneName(e)}
                                    value={this.state.newName}
                                    required="required" />
                            </td>
                            <td>
                                <input
                                    type="email"
                                    placeholder="Email coordinator"
                                    className="form-control"
                                    onChange={(e) => this.setNewPoliceZoneCoordinatorName(e)}
                                    value={this.state.newCoordinator} required="required" />
                            </td>
                            <td colSpan="2"></td>
                            <td className="text-right">
                                <button onClick={(e) => this.addPoliceZone(e)} className="btn btn-success">Toevoegen</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}
