import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import authService from './api-authorization/AuthorizeService';

const EditPoliceZone = ({ policeZone = null,
    show = false,
    handleClose = () => console.log('close modal'),
}) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const savePoliceZone = async () => {
        if (name !== '' && email !== '') {
            policeZone = {
                ...policeZone, ...{ name, mainContact: email }
            };

            const token = await authService.getAccessToken();

            try {
                const response = await fetch('/policezone', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(policeZone),
                });

                handleClose(true, policeZone);

            } catch (exception) { console.log(exception); }
        }
    };

    useEffect(() => {
        setName(policeZone.name);
        setEmail(policeZone.mainContact);
    }, [policeZone]);

    const cancel = () => {
        handleClose(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Bewerk zone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="policezoneform">
                    <div className="form-group">
                        <label htmlFor="name">Naam politiezone</label>
                        <input type="text"
                            className="form-control"
                            id="name"
                            placeholder="Naam politiezone"
                            required="required"
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email coordinator</label>
                        <input type="text"
                            className="form-control"
                            id="email"
                            placeholder="Email coordinator"
                            required="required"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancel}>
                    Sluiten
                </Button>
                <Button variant="success" onClick={savePoliceZone}>
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { EditPoliceZone };
