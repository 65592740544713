import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h1>BIN administratie overzicht</h1>
                <p>Welkom in het administratie gedeelte van de BIN ingave tool. Hier kunnen de politiezones beheerd worden en is alle ingegeven data opvraagbaar.</p>
                <ul>
                    <li><a href="/police-zones">Politie zones beheren</a></li>
                    <li><a href="/all-bin-data">Alle ingegeven BIN informatie bekijken</a></li>
                </ul>
                <p>Indien u toegang nodig heeft tot deze tool en nog niet over een login beschikt, kan u bovenaan klikken op registreren.</p>

            </div>
        );
    }
}
